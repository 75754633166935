.conversation {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.conversation::-webkit-scrollbar {
    display: none;
}

.resources {
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.resources::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.health {
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.health::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.profile {
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.profile::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.discussion {
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.discussion::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}