.control-panel-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .control-panel-header {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  
  .control-panel-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .page-links-section, .widgets-section {
    flex: 1 1 45%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .page-links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .page-card {
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .page-card:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
  }
  
  .widgets-placeholder {
    text-align: center;
    padding: 20px;
    border: 2px dashed #ddd;
    border-radius: 5px;
    color: #999;
  }  